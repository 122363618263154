import React from 'react';
import Navbar from './components/Navbar';
import './App.css';



import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import ContactUs from './components/pages/ContactUs';
import SignUp from './components/pages/SignUp';
import ScrollToTop from './components/ScrollToTop';
import LandingPage from './components/LandingPage';
import Payment from './components/Payment';


function App() {
  return (
    <>
      <Router>
        <ScrollToTop/>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/services' component={Services} />
          <Route path='/products' component={Products} />
          <Route path='/contactus' component={ContactUs} />
          <Route path='/SignUp' component={SignUp} />
          <Route path='/LandingPage' component={LandingPage} />
          <Route path='/Payment' component={Payment} />
        </Switch>
        
      </Router>
    </>
  );
}

export default App;