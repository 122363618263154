import React from 'react';
import { Button } from './globalStyles';

import { IconContext } from 'react-icons/lib';
import { FcIdea, FcLike,FcBullish } from "react-icons/fc";
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature
} from './Pricing.elements';

function Pricing1() {
  return (
    <IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
      <PricingSection>
        <PricingWrapper>
          <PricingHeading>Our Products</PricingHeading>
          <PricingContainer>
            <PricingCard to='/Payment'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <FcIdea />
                </PricingCardIcon>
                <PricingCardPlan>Starter</PricingCardPlan>
                <PricingCardCost>$149.99</PricingCardCost>
                <PricingCardLength>2-3 Pages</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>7 Days Delivery</PricingCardFeature>
                  <PricingCardFeature>Content Upload</PricingCardFeature>
                  <PricingCardFeature>Responsive Design</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Buy Now</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/Payment'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <FcLike />
                </PricingCardIcon>
                <PricingCardPlan>Gold</PricingCardPlan>
                <PricingCardCost>$389.99</PricingCardCost>
                <PricingCardLength>5-7 pages</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>12 Days Delivery</PricingCardFeature>
                  <PricingCardFeature>Plugins/Extensions</PricingCardFeature>
                  <PricingCardFeature>E-Commerce Functionality</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Buy Now</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/Payment'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <FcBullish />
                </PricingCardIcon>
                <PricingCardPlan>Diamond</PricingCardPlan>
                <PricingCardCost>$669.99</PricingCardCost>
                <PricingCardLength>Unlimited Pages*</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>28 Days Delivery</PricingCardFeature>
                  <PricingCardFeature>E-commerce Functionality</PricingCardFeature>
                  <PricingCardFeature>24/7 Support</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Buy Now</Button>
              </PricingCardInfo>
            </PricingCard>
          </PricingContainer>
        </PricingWrapper>
        <h5>Note: Price include <i>Hosting Cost only. </i></h5>
      </PricingSection>
      
    </IconContext.Provider>
  );
}
export default Pricing1;
