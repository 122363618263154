import React from 'react';
import '../../App.css';

import Footer from '../Footer';

import Pricing1 from '../Pricing1';
import HeroSection1 from '../HeroSection1'

// import NewCards1 from '../NewCards1'
// import NewCards1 from '../NewCards1';

// export default function Products() {
//   return <h1 className='products'>PRODUCTS</h1>;
// }

function Products() {
  return (
    <>
      {/* <HeroSection /> */}
      <Pricing1/>
      <HeroSection1/>
      <Footer />

    </>
  );
}

export default Products; 