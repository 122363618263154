import React from 'react';
import '../../App.css';
import Footer from '../Footer';

import Business from '../Business';
// export default function Services() {
//   return <h1 className='services'>SERVICES</h1>;
// }


function Services() {
  return (
    <>
     
      {/* <HeroSection1/> */}
      <Business/>
    
      <Footer />

    </>
  );
}

export default Services; 