import React from 'react';
import '../App.css';

import './HeroSection1.css';

function HeroSection1() {
  return (
    <div className='hero-container1'>
      <image src ='/images/1.png'/>
      {/* <video src='/videos/vd1.mp4' autoPlay loop muted /> */}
      <h1>DREAM IT, BELIEVE IT , BE IT ! - Unknown</h1>
      {/* <p>What are you waiting for?</p> */}
      {/* <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          ORDER NOW
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
        >
          HOW IT WORKS <i className='far fa-play-circle' />
        </Button>
      </div> */}
    </div>
  );
}

export default HeroSection1;