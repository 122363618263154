import React from 'react';
import '../App.css';
import reactDom from "react-dom";
import './Payment.css';
import StripeCheckout from "react-stripe-checkout"

function handleToken() {

}

function Payment() {
  return (
    <div className='landing-container2'>
      
      {/* <video src='/videos/vd1.mp4' autoPlay loop muted /> */}
      {/* <h2>This page is under development !!</h2> */}
      <p>This page is under development ! </p>
      
      <p></p>
      <p></p>
      <h3> Please drop a mail on : support@codeier.com</h3>
      <div></div>
     <div>
         {/* <StripeCheckout
            stripeKey="pk_test_51HpFsHBV3I366is6sIjYnbLjFWkRxK42QnW5RC5BWo6Klo0nqk29K94rVtYZXJwKywvx8WJH1WtjWNXqt8ZnGaLo00fw11JGR3"
            token = {handleToken}
         /> */}
     </div>
    </div>
  );
}

export default Payment;