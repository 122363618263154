import React from 'react';
import '../../App.css';

import Business from '../Business';
import Footer from '../Footer';
// export default function SignUp() {
//   return <h1 className='sign-up'>Loading.......</h1>;
// }

function SignUp() {
  return (
    <>
      
      {/* <HeroSection1/> */}
      <Business/>
      <Footer />

    </>
  );
}

export default SignUp; 