import firebase from "firebase";

var firebaseApp = firebase.initializeApp({
 
    apiKey: "AIzaSyB_Cc-6uN5jaM2gvBO7YzJnrmLT757j28c",
    authDomain: "react-webhippo.firebaseapp.com",
    databaseURL: "https://react-webhippo.firebaseio.com",
    projectId: "react-webhippo",
    storageBucket: "react-webhippo.appspot.com",
    messagingSenderId: "717125804385",
    appId: "1:717125804385:web:dc2d0b9df2e2c03ecce520"
  
  
});

var db = firebaseApp.firestore();

export { db };