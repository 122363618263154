import React from 'react';
import '../App.css';

import './LandingPage.css';

function LandingPage() {
  return (
    <div className='landing-container1'>
      
      {/* <video src='/videos/vd1.mp4' autoPlay loop muted /> */}
      <h1>This page is under development !!</h1>
      <p>Please call us or Email us instead . </p>
     
    </div>
  );
}

export default LandingPage;