import React, { Component } from "react";
import { FaFileCode, FaCameraRetro, FaBrain, FaEuroSign } from "react-icons/fa";
import './Business.css'


import Title from "./Title";


export default class business extends Component {
 
  state = {
    business: [
      {
        icon: <FaFileCode />,
        title: "Development",
        
        info:
          "We use latest and stable code like ReactJS, CSS3 to code your website. BBC, Netflix, Dropbox, Coursera, IMDb, PayPal, Dailymotion and many other companies use reactJS."
      },
      
      {
        icon: <FaCameraRetro />,
        title: "Design",
        info:
          "This is how the user will attract to your page. if you are not sure how to di it by yourself, leave it to us. Because first impression matters."
      },
      
      {
        
        icon: <FaBrain />,
        title: "SEO",
        info:
          "Now reach to millions with our SEO optimised code so you can be seen more.?"
      },
      {
        icon: <FaEuroSign />,
        title: "E-Commerce",
        info:
          "We have solutions to all types of E-commerce requirment."
      }
    ]
  };
  
  
  
  render() {
      
    return (
      <section className="business">
       
        <Title title="WHAT WE DO" />
        
       
        {/* <image src ='/images/1.png'/> */}
        <div className="business-center">
          
          {this.state.business.map(item => {
            return (
              <article key={`item-${item.title}`} className="service">
                <span>{item.icon}</span>
                
                <h6>{item.title}</h6>
                <p>{item.info}</p>
              </article>
            );
          })}
        </div>
      </section>
    );
  }
}