import React from 'react';
import './Footer.css';
import { Button1 } from './Button1';
import { Link } from 'react-router-dom';
import { BackTop } from 'antd';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Do you want a customized quote for your requirement , just put your email id below.
        </p>
        <p className='footer-subscription-text'>
          call us on +31 645 841 545 or mail us on support@codeier.com
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button1
              className='btns'
              buttonStyle='btn--outline1'
              buttonSize='btn--large'
            >
              Get a Quote
            </Button1>
            {/* <Button buttonStyle='btn--primary'>Subscribe</Button> */}
          </form>
        </div>
      </section>
      {/* <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Testimonials</Link>s
            <Link to='/'>Careers</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/'>Contact</Link>
            <Link to='/'>Support</Link>
            <Link to='/'>Destinations</Link>
            <Link to='/'>Sponsorships</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Submit Video</Link>
            <Link to='/'>Ambassadors</Link>
            <Link to='/'>Agency</Link>
            <Link to='/'>Influencer</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link>
          </div>
        </div>
      </div> */}
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>



            <Link to='/' className='social-logo'>

              <img src="/images/logo.png" alt = "" width="40" />
              ODEier
            </Link>
          </div>
          <small class='website-rights'>CODEier. © 2020</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              {/* <i class='fab fa-facebook-f' /> */}
              <td onClick={()=> window.open("https://www.facebook.com/", "_blank")}><FontAwesomeIcon icon={faFacebook} size="1x" /></td>
              {/* <a href="https://www.facebook.com/" class='social-icon-link facebook'  > <FontAwesomeIcon icon={faFacebook} size="1x" /></a> */}
            </Link>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              {/* <i class='fab fa-instagram' /> */}
              <td onClick={()=> window.open("https://www.instagram.com/codeier/", "_blank")}><FontAwesomeIcon icon={faInstagram} size="1x" /></td>
              {/* <a href="https://www.instagram.com/codeier/" class='social-icon-link instagram'  > <FontAwesomeIcon icon={faInstagram} size="1x" /></a> */}
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >

              {/* <i class='fab fa-youtube' /> */}

              <td onClick={()=> window.open("https://www.youtube.com/", "_blank")}><FontAwesomeIcon icon={faYoutube} size="1x" /></td>
              {/* <a href="https://www.youtube.com/" class='social-icon-link youtube'  > <FontAwesomeIcon icon={faYoutube} size="1x" /></a> */}
            </Link>

            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <td onClick={()=> window.open("https://www.twitter.com/codeier1/", "_blank")}><FontAwesomeIcon icon={faTwitter} size="1x" /></td>
              {/* <a href="https://www.twitter.com/codeier1/" class='social-icon-link twitter'  > <FontAwesomeIcon icon={faTwitter} size="1x" /></a> */}
              {/* <i class='fab fa-twitter' /> */}
            </Link>

            <Link
              class='social-icon-link linkedin'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              {/* <a href="https://www.linkedin.com/company/codeier/" class='social-icon-link linkedin'  > <FontAwesomeIcon icon={faLinkedin} size="1x" /></a> */}
              {/* <i class='fab fa-linkedin' /> */}
              {/* Written New line so the link wil open in new tab */}
              <td onClick={()=> window.open("https://www.linkedin.com/company/codeier/", "_blank")}><FontAwesomeIcon icon={faLinkedin} size="1x" /></td>
            </Link>
            
          </div>
          <BackTop>
              <div classname="goTop" ><i class="fas fa-angle-double-up" size ="10x" color = '#fff'></i></div>
            </BackTop>
        </div>
        
      </section>
      
    </div>
  );
}

export default Footer;