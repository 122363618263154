import React from 'react';
import '../../App.css';

import HeroSection from '../HeroSection';
import HeroSection1 from '../HeroSection1';
import Footer from '../Footer';
import Pricing1 from '../Pricing1';

import Business from '../Business';
function Home() {
  return (
    <>
      <HeroSection />
      
      <Business/>
      {/* <Cards /> */}
      {/* <CardsProducts/> */}
      <Pricing1/>
      <HeroSection1/>
      
      <Footer/>
    </>
  );
}

export default Home;