import React from 'react';
import '../../App.css';
import Footer from '../Footer';

import AppContact from '../AppContact';
import '../AppContact.css';


function ContactUs() {
  return (
    <>
     
      <AppContact/>
       <Footer />

    </>
  );
}
  
export default ContactUs; 

// function ContactUs() {
//     return (
//       <div classname = "main">
//         <AppContact/>
//         <HeroSection1/>
//         <Footer />
  
//       </div>
//     );
//   }
  
//   export default ContactUs; 