import React from 'react';
import '../App.css';
import { Button } from './Button';
import { Button1 } from './Button1';
import './HeroSection.css';

import Typical from 'react-typical';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src='/videos/vd1.mp4' autoPlay loop muted />
      
      <h2>LET'S THE WORLD DISCOVER YOU!</h2>
      <Typical
        steps={['Web Design.', 500, 'Development.',500, 'eCommerce.',500,'SEO.', 500]}
        loop={Infinity}
        wrapper="p"
      />
      {/* <p><h2>YOU ! </h2></p> */}
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          ORDER NOW
        </Button>
        <Button1
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
        >
          CONTACT US <i class="fas fa-envelope"></i>
           {/* <i className='far fa-play-circle' /> */}
        </Button1>
      </div>
    </div>
  );
}

export default HeroSection;